//
// custom scss goes here
//

img {
  &.logo-home {
    height: 48px;
  }
}

.navbar-brand {
  img {
    height: 32px;
  }
}

footer {
  .navbar-brand {
    img {
      height: 28px;
    }
  }
}

.bg-teal-200 {
  background-color: $teal-200 !important;
}
.bg-teal-400 {
  background-color: $teal-400 !important;
}
.background-overlay-dark {
  &::after {
    opacity: 1 !important;
    background: rgba($dark,.75) !important;
  }
}
.background-overlay-dark-gradient {
  &::after {
    opacity: 1 !important;
    background: rgba($dark,.75) !important;
    background: -webkit-linear-gradient(rgba($dark,.5) 0%, rgba($dark,.9) 80%) !important;
    background: -moz-linear-gradient(rgba($dark,.5) 0%, rgba($dark,.9) 80%) !important;
    background: linear-gradient(rgba($dark,.5) 0%, rgba($dark,.9) 80%) !important;
  }
}
.background-overlay-teal {
  &::after {
    opacity: .6 !important;
    background: $teal-200 !important;
  }
}

.body--light {
  .q-footer {
    svg {
      &.logo {
        * {
          path {
            fill: rgba(0,0,0,.1);
          }
        }
      }
    }
  }
}
